<!--访客模块--人员上下设置--人员选择-->
<template>
  <div id="accessRolesChooseModal" v-loading="loading" :element-loading-text="`${$t('visitorRuleSetting.a1')}....`">
    <div class="header">
      <a-input v-model="form.value1" :placeholder="$t('visitorRuleSetting.a18')" allow-clear style="width:200px;margin-right:15px"/>
      <a-button type="primary" @click="inquire">{{ $t("visitorRuleSetting.a21") }}</a-button>
    </div>
    <div class="main" ref="main">
      <my-tabletitle>{{ $t("visitorRuleSetting.a9") }}</my-tabletitle>
      <a-table :columns="columns" :dataSource="accessRoles" :row-selection="rowSelection" :pagination="pagination" :rowKey="(record) => record.id" :scroll="size"></a-table>
    </div>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";

import moment from "moment";

export default {
  props: {
    accessRoleList: {
      type: Array,
      default:[],
    },
    reflush:{
      type:Boolean,
      default:false,
    }       
  }, 
  data() {
    return {
      accessRoles:[],
      selectedRowKeys:[],
      size: {  x: 500,y: 240 },
      title:this.$t('visitorRuleSetting.a4'),      
      form:{
            date1: moment().startOf("day"),
            date2: moment().endOf("day"),
            value1: "",
            condition1: "",            
            },
      loading: false,
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示10条数据
        showTotal: (total) => `${total} ${this.$t("visitorRuleSetting.a20")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
        },        
        {
          title: this.$t("visitorRuleSetting.a18"),
          dataIndex: "desc",
          ellipsis: true,
        },        
      ],
    };
  },
 
  mounted(){
   this.accessRoles = this.accessRoleList;
  },
  watch: {
    reflush(val){
      if(!val){
        this.clearSelectedRowKeys();
        this.accessRoles=[];
      }else{
        this.accessRoles = this.accessRoleList;
      }
    }
  },
  computed:{    
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          if(selectedRows!=null&&selectedRows.length>0){
            this.selectedRowKeys=selectedRowKeys;
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.$emit('selectedRows',selectedRows);//返回数据至父组件
          }
        },        
      };
    },
  },
  methods: {
    moment, 
    //根据条件查询通行模式 
    inquire(){
        let arr = [];
        for (let i = 0; i < this.accessRoleList.length; i++) {
            let {name} = this.accessRoleList[i];
            // let name = new String(ele.name+"");
            if(name.indexOf(this.form.value1)>=0){
              arr.push(this.accessRoleList[i]);
            }
        }
        this.accessRoles = arr;
    },  
    clearSelectedRowKeys(){
      this.selectedRowKeys= [];
    },
  },
  components: {
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
#accessRolesChooseModal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  overflow: hidden;
  margin-bottom: 15px;
}
.main {
  width: 100%;
  overflow: hidden;
}

</style>