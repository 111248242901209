<!--设置管理模块--警报管理页--警报设置详细信息页-->
<template>
  <div id="visitorRuleSetting" v-loading="loading" :element-loading-text="$t('alarm.a0')" >
    <div class="header">
      <my-headertitle>{{name}}</my-headertitle>
    </div>
    <div class="main">      
      <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row style="padding:5px;">
            <a-col :span="12" style="border-right:1px solid #e8e8e8;">
                <a-form-model-item :label="$t('visitorRuleSetting.a4')" prop="authorizationMode" ref="authorizationMode">
                      <a-radio-group v-model="form.authorizationMode">
                        <a-radio value="1">
                          {{$t('visitorRuleSetting.a5')}}
                        </a-radio>
                        <a-radio value="2">
                          {{$t('visitorRuleSetting.a6')}}
                        </a-radio>
                      </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item :label="$t('visitorRuleSetting.a7')"  prop="accessRolesDesc" ref="accessRolesDesc">
                        <a-list bordered :data-source="form.accessRolesDesc" style="height:260px;overflow:auto;">
                          <a-list-item slot="renderItem" slot-scope="item, index" :key="index">
                            {{(index+1) + '、' + item }}
                            <a-button type="primary" @click="onDeleteAccessRole(index)">{{$t('visitorRuleSetting.a8')}}</a-button>
                          </a-list-item>
                          <div slot="header">
                            <a-button type="primary" @click="onSelectAccessRoles">{{$t('visitorRuleSetting.a9')}}</a-button>
                          </div>
                          <!-- <div slot="footer">
                            Footer
                          </div> -->
                        </a-list>
                      </a-form-model-item> 
                      <a-form-model-item ref="timeLimit" :label="$t('visitorRuleSetting.a3')" prop="timeLimit">
                        <a-input
                          v-model="form.timeLimit"
                          @blur="
                            () => {
                              $refs.timeLimit.onFieldBlur();
                            }
                          "
                        />
                      </a-form-model-item>    
                      <a-form-model-item ref="parkArea" label="车辆通行区域" v-if="brand===21">
                        <a-select v-model="form.parkArea" placeholder="please select your zone" mode="multiple">
                                <a-select-option :value="item.no" v-for="(item,index) in carParkAreas" :key="index"> 
                                  {{item.name}}
                                </a-select-option>
                              </a-select>
                      </a-form-model-item> 
             </a-col>
            <a-col :span="12">
                 
                    
                    <a-form-model-item :label="$t('visitorRuleSetting.a10')" prop="type">
                      <a-checkbox-group 
                        :options="form.authenticatonTypes" 
                        v-model="form.authenticatioModes">            
                      </a-checkbox-group>
                    </a-form-model-item>    
                    <a-form-model-item ref="wechat" :label="$t('visitorRuleSetting.a1')" prop="wechat">
                      <a-radio-group v-model="form.wechat">
                        <a-radio value="1">
                          {{$t('visitorRuleSetting.a16')}}
                        </a-radio>
                        <a-radio value="2">
                          {{$t('visitorRuleSetting.a17')}}
                        </a-radio>
                      </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item ref="wechatURL" :label="$t('visitorRuleSetting.a2')" prop="wechatURL">
                      <a-input
                        v-model="form.wechatURL"
                        @blur="
                          () => {
                            $refs.wechatURL.onFieldBlur();
                          }
                        "
                      />
                    </a-form-model-item>
                    
            </a-col>
             
        </a-row>
        
      </a-form-model>
    </div>   
    <div class="footer">
        <div>

            <a-button type="primary" @click="onInfoSynchronous">{{$t('visitorRuleSetting.a26')}}</a-button>
            <a-button type="primary" @click="onBlackListSynchronous">{{$t('visitorRuleSetting.a27')}}</a-button>
            <a-button type="primary" @click="onDictionarySynchronous">{{$t('visitorRuleSetting.a28')}}</a-button>

        </div>
        <div>

            <a-button type="primary" @click="onSetting">{{$t('visitorRuleSetting.a11')}}</a-button>
        </div>
    </div> 
    <a-modal :title="title" :width="720" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <my-accessRolesChooseModal ref="ccaccessRolesChooseModal" :reflush="visible" :accessRoleList="accessRoleList" @selectedRows="onSelectedRows"></my-accessRolesChooseModal>
    </a-modal>   
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import {getVisitRuleList,updateVisitRuleValue,pushVisitDictionary,pushVisitorBlacklist,pushVisitor} from "../../api/visitor";
import accessRolesChooseModal from "./accessRolesChooseModal";
import { getAccessRoleList, getTPList} from "../../api/door";

export default { 
  props: {
    baseInfo: {
      type: Array,
    },
  }, 
  data() {
    return {
      title: this.$t('visitorRuleSetting.a12'),
      name: this.$t('visitorRuleSetting.a13'),    
      siteId:this.$route.query.id,  
      visible:false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      visitRuleConfig:[],
      visitRuleData:[],
      accessRoleList:[],//筛选后的通行模式
      accessRoleListAll:[],//所有通行模式
      form: {
        wechat: "",
        wechatURL: "",
        timeLimit: "",
        authorizationMode: "",//授权模式
        accessRoles: [],//通行模式
        accessRolesDesc:[],
        authenticatioModes: [],//认证模式
        authenticatonTypes:[],//认证模式类型
        parkArea:[],
      },  
      selectAccessRoles:[],   
      loading:false,
      params:{
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
      },
      carParkAreas:[],
      brand:0,
    };      
  },
  activated(){
      this.form={
        wechat: "",
        wechatURL: "",
        timeLimit: "",
        authorizationMode: "",//授权模式
        accessRoles: [],//通行模式
        accessRolesDesc:[],
        authenticatioModes: [],//认证模式
        authenticatonTypes:[],//认证模式类型
      }
      this.getAccessRoleList();
    
  },
  mounted() {
   
  }, 
  methods: {
    onDeleteAccessRole(index){
      let i = parseInt(index);
      this.form.accessRoles.splice(i,1);
      this.form.accessRolesDesc.splice(i,1);
      this.filterAccessRoleList();
    },
    onSelectedRows(msg){
      this.selectAccessRoles=msg;      
    },
    handleOk(){
      this.visible = false;
      if(this.selectAccessRoles!=null&&this.selectAccessRoles.length>0){
        for(let i=0;i<this.selectAccessRoles.length;i++){
          this.form.accessRoles.push({Id: this.selectAccessRoles[i].id+"",
                                      Name: "",
                                      No: ""});                                      
          this.form.accessRolesDesc.push(this.selectAccessRoles[i].desc);
        }
      }
    },
    handleCancel(){
      this.visible = false;
      this.selectAccessRoles=[];
    },
    //选择通行模式
    onSelectAccessRoles(){      
      
      this.visible = true;
      // setTimeout(() => {
      // }, 100);
      
    },
    //访客信息微信同步
    onInfoSynchronous() {
       this.$confirm({
          title: '确认要进行访客信息微信同步吗？',
          centered: true,
          onOk: () => { 
              this.loading = true;
              pushVisitor(this.params)
              .then((res) => {              
                  this.loading = false;
                  let { data } = res;       
                  console.log("pushVisitor res:",data); 
                  if(res.errorCode == '00'){
                    this.$message.success(res.msg);
                  }else{
                    this.$message.error(res.msg);
                  }   
              })
              .catch((err) => {
                  this.loading = false;
                  console.log(err);
              });
          }
        });
    },
    //访客黑名单微信同步
    onBlackListSynchronous() {
        this.$confirm({
          title: '确认要进行访客黑名单微信同步吗？',
          centered: true,
          onOk: () => { 
              this.loading = true;
              pushVisitorBlacklist(this.params)
              .then((res) => {              
                  this.loading = false;
                  let { data } = res;       
                  console.log("pushVisitorBlacklist res:",data); 
                  if(res.errorCode == '00'){
                    this.$message.success(res.msg);
                  }else{
                    this.$message.error(res.msg);
                  }   
              })
              .catch((err) => {
                  this.loading = false;
                  console.log(err);
              });
          }
        });
    },
    //字典参数微信同步
    onDictionarySynchronous() {
        this.$confirm({
          title: '确认要进行字典参数微信同步吗？',
          centered: true,
          onOk: () => { 
              this.loading = true;
              pushVisitDictionary(this.params)
              .then((res) => {              
                  this.loading = false;
                  let { data } = res;       
                  console.log("pushVisitDictionary res:",data); 
                 if(res.errorCode == '00'){
                    this.$message.success(res.msg);
                  }else{
                    this.$message.error(res.msg);
                  }   
              })
              .catch((err) => {
                  this.loading = false;
                  console.log(err);
              });
          }
        });
    },
    //设置
    onSetting(){
      this.convertVisitRuleData();      
      let par={
        list:this.visitRuleConfig,
      } 
      console.log("updateVisitRuleValue par:",par); 

      this.loading = true;
      updateVisitRuleValue(this.siteId,par)
      .then((res) => {              
          this.loading = false;
          let { data } = res;                    
          if(data==0)
            this.$message.info(this.$t('visitorRuleSetting.a14')); 
          else
            this.$message.error(this.$t('visitorRuleSetting.a15')); 
          console.log("updateVisitRuleValue res:",data);        
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },  
    convertVisitRuleData(){
      this.visitRuleConfig.length=0;
      if(this.visitRuleData!=null && this.visitRuleData.length>0){
        let visitConfig = {};
        for(let i=0;i<this.visitRuleData.length;i++){   
          visitConfig = {};          
          switch (this.visitRuleData[i].kind) {
            case "VISITOR_ACCESS_WECHAT":
              visitConfig.value=this.form.wechat;             
              break;
            case "VISITOR_ACCESS_WECHAT_URL":
              visitConfig.value=this.form.wechatURL;
              break;
            case "VISITOR_ACCESS_TIME_LIMIT":
              visitConfig.value=this.form.timeLimit;
              break;
            case "VISITOR_ACCESS_AUTHORIZATION_MODE"://授权模式
              visitConfig.value=this.form.authorizationMode;
              break;
            case "VISITOR_ACCESS_ROLE"://通行模式
              // this.visitRuleData[i].values=this.form.accessRoles;
              if(this.form.accessRoles!=null&&this.form.accessRoles.length>0){
                visitConfig.value=this.form.accessRoles[0].Id+"";
                for(let j=1;j<this.form.accessRoles.length;j++){
                  visitConfig.value=visitConfig.value+","+this.form.accessRoles[j].Id;
                }
              }            
              break;
            case "VISITOR_PASS_MODE"://认证模式
              if(this.form.authenticatioModes!=null&&this.form.authenticatioModes.length>0)
                visitConfig.value = this.form.authenticatioModes.join(",");
              break;
            case "VISITOR_ACCESS_PARK_AREA"://访客车辆通行区域
              if(this.form.parkArea!=null&&this.form.parkArea.length>0)
                visitConfig.value = this.form.parkArea.join(",");
              break;
            default:
              break;
          }
          visitConfig.id = this.visitRuleData[i].id;
          visitConfig.kind=this.visitRuleData[i].kind;
          this.visitRuleConfig.push(visitConfig);
        }
      }
    },
    getVisitRuleList(){
      this.loading = true;
      getVisitRuleList(this.siteId)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log('getVisitRuleList',data);
          //转换为form值
          if(data!=null && data.length>0){
            this.visitRuleData = data;
            for(let i=0;i<data.length;i++){
              switch (data[i].kind) {
                case "VISITOR_ACCESS_WECHAT":
                  this.form.wechat = data[i].value;
                  break;
                case "VISITOR_ACCESS_WECHAT_URL":
                  this.form.wechatURL = data[i].value;
                  break;
                case "VISITOR_ACCESS_TIME_LIMIT":
                  this.form.timeLimit = data[i].value;
                  break;
                case "VISITOR_ACCESS_AUTHORIZATION_MODE"://授权模式
                  this.form.authorizationMode = data[i].value;
                  break;
                case "VISITOR_ACCESS_ROLE"://通行模式
                  // console.log("getVisitRuleList data:",  data[i].values);
                  // console.log("getVisitRuleList accessRoleListAll:", this.accessRoleListAll);
                  // console.log("getVisitRuleList form:", this.form);
                  if( data[i].values!=null){
                     let arrs =  data[i].values;
                     if(this.accessRoleListAll!=null){
                       this.form.accessRolesDesc.length=0;
                       for(let k=0;k< arrs.length;k++){
                         if( arrs[k].Id==='')
                            continue;
                         for(let j=0;j<this.accessRoleListAll.length;j++){
                           if(parseInt(arrs[k].Id) == this.accessRoleListAll[j].id){
                              this.form.accessRolesDesc.push(this.accessRoleListAll[j].desc);
                              this.form.accessRoles.push({Id: this.accessRoleListAll[j].id+"",
                                      Name: "",
                                      No: ""})
                           }
                         }
                       }
                     }
                  }
                  break;
                case "VISITOR_PASS_MODE"://认证模式
                  if(data[i].value!=null)
                    this.form.authenticatioModes = data[i].value.split(",");
                  this.form.authenticatonTypes= data[i].params.data;
                  for(let n=0;n<this.form.authenticatonTypes.length;n++){
                    this.form.authenticatonTypes[n].label=this.form.authenticatonTypes[n].name;
                  }
                  break;
                case "VISITOR_ACCESS_PARK_AREA"://访客车辆通行区域
                    if(data[i].value!=null && data[i].value!='')
                        this.form.parkArea = data[i].value.split(",");
                      this.carParkAreas = data[i].params;
                      this.brand = data[i].brand;
                    break;
                default:
                  break;
              }
            }
          }

          this.filterAccessRoleList();
          
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },  
    getAccessRoleList(){
      this.loading = true;
      console.log("getAccessRoleList par:",this.$route.query.id);
      getAccessRoleList(this.$route.query.id)
        .then((res) => {              
          this.loading = false;
          let { data } = res;          
          this.accessRoleList.length=0;            
          if(data!=null){ 
            this.accessRoleListAll = data.accessRoleList;     
            this.accessRoleListAll.forEach(element => {
              let ss = element.accessRoleNo+"";
              element.desc = ss.padStart(3,'0')+" "+element.name
            });
            this.getVisitRuleList();
          }         
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    filterAccessRoleList(){
      this.accessRoleList = new Array();
      for(let i=0;i<this.accessRoleListAll.length;i++){
        let isExist = false;
        for(let j=0;j<this.form.accessRoles.length;j++){  
          if(this.accessRoleListAll[i].id+"" === this.form.accessRoles[j].Id){
            isExist=true;
            break;
          }                    
        }
        if(!isExist){
          this.accessRoleList.push(this.accessRoleListAll[i]);
        }
      }
    }  
  },
  components: {
    "my-headertitle": headerTitle,
    "my-accessRolesChooseModal":accessRolesChooseModal,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#visitorRuleSetting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-family: 'Microsoft YaHei';
}
.header {
  padding: 0 20px;
}
.main {
  width: 100%;
  height: 100%;
  overflow: auto;
}
button {
  /* width: 100px; */
  margin-right: 15px;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
div /deep/ .ant-checkbox-group-item {
    display: block;
  }
</style>